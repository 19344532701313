@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&family=Roboto:wght@300&family=Staatliches&display=swap");

.fontlogo {
  font-family: "Staatliches", cursive;
  font-size: 200%;
}

.headerNav {
  font-family: "Poppins", sans-serif;
  font-size: 130%;
}
.fontText {
  font-family: "Staatliches", cursive;
  font-size: 300%;
}
